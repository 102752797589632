/* eslint-disable no-underscore-dangle */
/* eslint-disable react/no-danger */
import { graphql } from 'gatsby';
import React from 'react';
import BlueArrow from '../src/components/BlueArrow/BlueArrow';
import { Freya } from '../src/components/Freya/Freya';
import Heimdall from '../src/components/Heimdall/Heimdall';
import Hellheim from '../src/components/Hellheim/Hellheim';
import Hod from '../src/components/Hod/Hod';
import Layout from '../src/components/Layout/Layout';
import NeoAesir from '../src/components/NeoAesir/NeoAesir';
import { Odin } from '../src/components/Odin/Odin';
import H2 from '../src/components/Titles/TitleH2/TitleH2';
import H3 from '../src/components/Titles/TitleH3/TitleH3';
import Ymir from '../src/components/Ymir/Ymir';
import SEO from '../src/helpers/seo';
import tt from '../src/helpers/translation';
import './ficha_grupos.scss';

export const pageQuery = () => graphql`
  query allGroupsQuery($section: String!, $locale: String!) {
    allGroupsHeaderBlock(filter: { tag: { eq: $section } }) {
      edges {
        node {
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    allOdinDataTitle(filter: { tag: { eq: $section }, lang: { eq: $locale } }) {
      edges {
        node {
          title
          subtitle
          description
        }
      }
    }
    allGroupsSeoData(filter: { tag: { eq: $section }, lang: { eq: $locale } }) {
      edges {
        node {
          _0 {
            title
          }
          _1 {
            description
          }
        }
      }
    }
    allGroupThorDataCard(filter: { tag: { eq: $section }, lang: { eq: $locale } }) {
      edges {
        node {
          title
          rssImg
          description
          ctaText1
          cta1
          size
          color1
        }
      }
    }
    allGroupOdinImage(filter: { tag: { eq: $section }, lang: { eq: $locale } }) {
      edges {
        node {
          image
          image_alt
        }
      }
    }
    imagefreya: allGroupOdinImage(filter: { tag: { eq: $section }, lang: { eq: $locale } }) {
      edges {
        node {
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          image_alt
        }
      }
    }
    allGroupsMainText(filter: { tag: { eq: $section }, lang: { eq: $locale } }) {
      edges {
        node {
          title
          body
        }
      }
    }
    allGroupsMainText2(filter: { tag: { eq: "grupos-escolares" }, lang: { eq: $locale } }) {
      edges {
        node {
          body
        }
      }
    }
    allImageEdulonia(filter: { tag: { eq: "grupos-escolares" }, lang: { eq: $locale } }) {
      edges {
        node {
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          alt
        }
      }
    }
    allFichaGruposBreadCrumbBlock(filter: { tag: { eq: $section }, lang: { eq: $locale } }) {
      edges {
        node {
          destination_url
          name
        }
      }
    }
    allFichaGruposRichData(filter: { tag: { eq: $section }, lang: { eq: $locale } }) {
      edges {
        node {
          rich_markup {
            color_css_class
            og_description
            og_title
            twitter_description
            twitter_title
            created_at
            updated_at
            rich_markup_type
          }
        }
      }
    }
    parquesFAQ: allPersonasDiscapacidadFaq(
      filter: { lang: { eq: $locale }, faq_type: { eq: "parques" } }
      sort: { fields: position, order: ASC }
    ) {
      edges {
        node {
          question
          answer
          faq_type
        }
      }
    }
    clubFAQ: allPersonasDiscapacidadFaq(
      filter: { lang: { eq: $locale }, faq_type: { eq: "club" } }
      sort: { fields: position, order: ASC }
    ) {
      edges {
        node {
          question
          answer
          faq_type
        }
      }
    }
    atraccionesFAQ: allPersonasDiscapacidadFaq(
      filter: { lang: { eq: $locale }, faq_type: { eq: "atracciones" } }
      sort: { fields: position, order: ASC }
    ) {
      edges {
        node {
          question
          answer
          faq_type
        }
      }
    }
    hotelesFAQ: allPersonasDiscapacidadFaq(
      filter: { lang: { eq: $locale }, faq_type: { eq: "hoteles" } }
      sort: { fields: position, order: ASC }
    ) {
      edges {
        node {
          question
          answer
          faq_type
        }
      }
    }
    parquesFAQTitle: allPersonasDiscapacidadFaqTitle(
      filter: { lang: { eq: $locale }, title: { eq: "FAQs Parques" } }
    ) {
      nodes {
        tag
        lang
      }
    }
    clubFAQTitle: allPersonasDiscapacidadFaqTitle(
      filter: { lang: { eq: $locale }, title: { eq: "FAQs Accesos" } }
    ) {
      nodes {
        tag
        lang
      }
    }
    atraccionesFAQTitle: allPersonasDiscapacidadFaqTitle(
      filter: { lang: { eq: $locale }, title: { eq: "FAQs Atracciones" } }
    ) {
      nodes {
        tag
        lang
      }
    }
    hotelesFAQTitle: allPersonasDiscapacidadFaqTitle(
      filter: { lang: { eq: $locale }, title: { eq: "FAQs Hoteles" } }
    ) {
      nodes {
        tag
        lang
      }
    }
  }
`;

const FichaGrupos = ({
  data: {
    allOdinDataTitle,
    allGroupsHeaderBlock,
    allGroupOdinImage,
    allGroupThorDataCard,
    allImageEdulonia,
    allGroupsMainText,
    allGroupsSeoData,
    allFichaGruposBreadCrumbBlock,
    allFichaGruposRichData,
    allGroupsMainText2,
    parquesFAQ,
    clubFAQ,
    atraccionesFAQ,
    hotelesFAQ,
    parquesFAQTitle,
    clubFAQTitle,
    atraccionesFAQTitle,
    hotelesFAQTitle,
  },
  pageContext: { pageName, section, options, locale, url },
}) => {
  const heimdallData = {
    name: allOdinDataTitle.edges[0].node.title,
    image: {
      url: allGroupsHeaderBlock.edges[0].node.localImage.childImageSharp.fluid,
    },
  };

  const text = {
    title: allOdinDataTitle.edges[0].node.subtitle,
    description: allOdinDataTitle.edges[0].node.description,
  };

  const odinData = {
    image: allGroupOdinImage.edges[0].node.image,
    alt: allGroupOdinImage.edges[0].node.image_alt,
    title: allGroupThorDataCard.edges[0].node.title,
    rssImg: true,
    description: allGroupThorDataCard.edges[0].node.description,
    buttons: {
      cta1: allGroupThorDataCard.edges[0].node.cta1,
      ctaText1: allGroupThorDataCard.edges[0].node.ctaText1,
      size: allGroupThorDataCard.edges[0].node.size,
      color1:
        section === 'personas-discapacidad-pmr'
          ? 'transparente'
          : allGroupThorDataCard.edges[0].node.color1,
      blank: section === 'grupos-escolares' || section === 'personas-discapacidad-pmr',
    },
  };

  const freyaCardData = {
    cards: [
      {
        image:
          section === 'grupos-escolares'
            ? allImageEdulonia.edges[0].node.localImage.childImageSharp.fluid
            : '',
        altImage: section === 'grupos-escolares' ? allImageEdulonia.edges[0].node.alt : '',
        title: tt('Colonias de inglés en PortAventura World', locale),
        description: tt('¡Descubre las colonias más divertidas!', locale),
        imgsize: 'promoted',
        buttons: {
          ctaText: tt('MÁS INFORMACIÓN', locale),
          cta: tt('https://www.edulonia.com/es/colonies-escolars/portaventura-world/', locale),
          size: 'alone',
          color: 'white',
        },
      },
    ],
  };
  const freyaCardData2 = {
    cards: [
      {
        image:
          section === 'grupos-escolares'
            ? allImageEdulonia.edges[1].node.localImage.childImageSharp.fluid
            : '',
        altImage: section === 'grupos-escolares' ? allImageEdulonia.edges[1].node.alt : '',
        title: tt('Descubre el nuevo proyecto educativo PortAventura EcoEduca.', locale),
        description: tt(
          'Talleres de 50 minutos sobre biodiversidad y residuo cero para alumnos de primaria y secundaria.',
          locale
        ),
        imgsize: 'promoted',
        buttons: {
          ctaText: tt('MÁS INFO', locale),
          cta: tt(
            'https://s3-eu-west-1.amazonaws.com/portaventura-world-production-files/wilson_cms/documents/documents/000/013/550/original/EcoEduca_CAST.pdf',
            locale
          ),
          size: 'alone',
          color: 'white',
          blank1: true,
        },
      },
    ],
  };

  const textTitle = {
    title: allGroupsMainText.edges[0].node.title,
  };

  const ymirData = {
    cards: [
      {
        title: tt('HORARIOS Y CALENDARIO', locale),
        buttonCard: tt('DESCUBRIR', locale),
        link: tt('/horarios-calendario', locale),
        icon: 'calendar',
      },
      {
        title: tt('CÓMO LLEGAR AL RESORT', locale),
        buttonCard: tt('DESCUBRIR', locale),
        link: tt('/destino/planificar/como-llegar-a-portaventura', locale),
        icon: 'LOCATION',
      },
      {
        title: tt('DESCUENTOS EN ENTRADAS ONLINE', locale),
        buttonCard: tt('DESCUBRIR', locale),
        link: tt('/promociones', locale),
        icon: 'Methodofpayment',
      },
    ],
  };

  const hellHeimFAQs = {
    parques: {
      title: parquesFAQTitle.nodes[0].tag,
      text: '',
      menus: parquesFAQ.edges.map(({ node }) => ({
        title: node.question,
        text: node.answer,
      })),
      titleType: 'h3',
      icon: 'park',
      id: 'park',
    },
    club: {
      title: clubFAQTitle.nodes[0].tag,
      text: '',
      menus: clubFAQ.edges.map(({ node }) => ({
        title: node.question,
        text: node.answer,
      })),
      titleType: 'h3',
      icon: 'pass',
      id: 'pass',
    },
    atracciones: {
      title: atraccionesFAQTitle.nodes[0].tag,
      text: '',
      menus: atraccionesFAQ.edges.map(({ node }) => ({
        title: node.question,
        text: node.answer,
      })),
      titleType: 'h3',
      icon: 'park',
      id: 'park',
    },
    hoteles: {
      title: hotelesFAQTitle.nodes[0].tag,
      text: '',
      menus: hotelesFAQ.edges.map(({ node }) => ({
        title: node.question,
        text: node.answer,
      })),
      titleType: 'h3',
      icon: 'hotel',
      id: 'hotel',
    },
  };

  return (
    <Layout layoutFromOld locale={locale} pageName={pageName} options={options}>
      <SEO
        title={allGroupsSeoData.edges[0].node._0.title}
        description={allGroupsSeoData.edges[0].node._1.description}
        // canonicalLink={`https://www.portaventuraworld.com${this.props.location.pathname}`}
        pathname={url}
        lang={locale}
      />
      <div className="is-fullWidth">
        <Heimdall data={heimdallData} />
        <div className="general-index">
          <div className="vacactional-groups-container">
            <NeoAesir
              locale={locale}
              pageName={pageName}
              options={options}
              title={allFichaGruposBreadCrumbBlock.edges[0].node.name}
            />
            <Hod
              data={allFichaGruposRichData.edges[0].node.rich_markup}
              url={url}
              img={allGroupsHeaderBlock.edges[0].node.localImage.childImageSharp.fluid}
            />
            <BlueArrow />
            <div className="title-and-subtitle">
              <H2 data={text} />
              {/* <span className="subtitle">
                <div dangerouslySetInnerHTML={{ __html: text.subtitle }} />
              </span> */}
            </div>
            <Odin data={odinData} locale={locale} />
            <div className="text-formatted">
              <H3 data={textTitle} />
              <div
                dangerouslySetInnerHTML={{
                  __html: allGroupsMainText.edges[0].node.body,
                }}
              />
            </div>
            {section === 'grupos-escolares' ? (
              <>
                <div className="second-text-container">
                  <div
                    className="second-text"
                    dangerouslySetInnerHTML={{
                      __html: allGroupsMainText2.edges[0].node.body,
                    }}
                  />
                  <div className="freyaContainer">
                    {locale === 'es' || locale === 'ca' || locale === 'en' ? (
                      <>
                        <Freya data={freyaCardData} />
                      </>
                    ) : null}
                    <Freya data={freyaCardData2} />
                  </div>
                </div>
              </>
            ) : null}

            {section === 'personas-discapacidad-pmr' && (
              <div className="personasDiscapacidad">
                <Hellheim data={hellHeimFAQs.parques} />
                <Hellheim data={hellHeimFAQs.club} />
                <Hellheim data={hellHeimFAQs.atracciones} />
                <Hellheim data={hellHeimFAQs.hoteles} />
              </div>
            )}
            <Ymir data={ymirData} />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default FichaGrupos;
